export function splitStringUsingRegex(inputString) {
    const characters = [];
    const regex = /[\s\S]/gu;

    let match;

    while ((match = regex.exec(inputString)) !== null) {
        characters.push(match[0]);
    }

    return characters;
}

export function formatRichText(text) {
    const StoryblokClient = require('storyblok-js-client');

    let Storyblok = new StoryblokClient({
        accessToken: 'CTyAvK77nXur4uBu7NXCbwtt',
    });
    return { __html: Storyblok.richTextResolver.render(text) };
}

export function getComponentByName(name, blok) {
    const index = blok?.body?.findIndex((e) => e.component === name);
    return blok.body[index];
}
