import React from 'react';
import { motion } from 'framer-motion';
import { splitStringUsingRegex } from '../shared/utils';

import { ReactComponent as Instagram } from '../svg/instagram.svg';
import { ReactComponent as Youtube } from '../svg/youtube.svg';
import { ReactComponent as Telegram } from '../svg/telegram.svg';
import { ReactComponent as Arrow } from '../svg/arrow.svg';

const charVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
};

function Home({ blok }) {
    const textChars = splitStringUsingRegex(blok.Headline);

    const year = new Date().getFullYear();

    return (
        <div className='App'>
            <motion.h1 initial='hidden' whileInView='reveal' transition={{ staggerChildren: 0.015 }}>
                {textChars.map((char, i) => (
                    <motion.span className='title' key={char + i} transition={{ duration: 0.15 }} variants={charVariants}>
                        {char}
                    </motion.span>
                ))}
            </motion.h1>
            <div className='links'>
                <a target='_blank' href='https://www.youtube.com/@davidefuregato'>
                    <Youtube />
                    @davidefuregato
                </a>
                <a target='_blank' href='https://www.instagram.com/davide.furegato'>
                    <Instagram />
                    @davide.furegato
                </a>
                <a target='_blank' href='https://t.me/davidefuregato'>
                    <Telegram />
                    @davidefuregato
                </a>
            </div>
            <div className='cards'>
                {blok.Cards.map((item, i) => (
                    <div className='card' key={i}>
                        <div>
                            <img src={item.immagine.filename ? item.immagine.filename : ''} />
                            <div>
                                <h3>{item.titolo}</h3>
                                <p>{item.descrizione}</p>
                            </div>
                        </div>
                        <a className='join desktop' target='_blank' href={item.link.url}>
                            {item.button_text}
                        </a>
                        <a className='join mobile' target='_blank' href={item.link.url}>
                            <Arrow />
                        </a>
                    </div>
                ))}
            </div>
            <p style={{ color: 'hsl(0, 0%, 50%)' }}>&#169; {year} Davide Furegato</p>
        </div>
    );
}

export default Home;
