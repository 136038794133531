import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './Home';
import { getComponentByName } from '../shared/utils';

function Page({ blok }) {
    const homeBlok = getComponentByName('Home', blok);
    return (
        <Router>
            <Routes>
                <Route path='/home' element={<Home blok={homeBlok} />} />
            </Routes>
        </Router>
    );
}

export default Page;
