import { useEffect } from 'react';
import './App.css';
import { useStoryblok, StoryblokComponent } from '@storyblok/react';

function App() {
    let slug = window.location.pathname === '/' ? 'home' : 'home';

    useEffect(() => {
        if (window.location.pathname === '/') {
            window.location.replace('/home');
        }
    }, []);

    let params = { version: 'publish' };

    const story = useStoryblok(slug, params);
    if (!story || !story.content) {
        return <div></div>;
    }

    return <StoryblokComponent blok={story.content} />;
}

export default App;
